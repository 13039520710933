
import Vue from "vue";
import SpinnerModule from "@/store/modules/spinnerModule";
import SnackModule from "@/store/modules/errorSnackbar";
import SelectoresModule from "@/store/modules/selectoresModule";
import { ApiErrorResponse, ApiSistemaPagos, AxiosHttpClient, getUrl, ApiList } from "apd.apiconnectors";
import { CorteCaja, Operacion, Pago, ModoPago, EstatusPago, TipoDenominacion, EstatusOperacion } from "apd.models";
import { mdiFileDocument, mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { DataTableHeader } from "vuetify";
import { facade } from "vue-input-facade";
import { Rules } from "@/models/rules";

export default Vue.extend({
  name: "DetalleCorteCaja",
  components: {
    SelectorCajeros: () => import("@/components/selectores/cajeros.vue"),
    SelectorCortes: () => import("@/components/selectores/cortes.vue"),
    Boton: () => import("@/components/botones/boton.vue"),
    DialogReportes: () => import("@/components/dialogReportes.vue"),
  },
  directives: {
    facade,
  },
  data() {
    return {
      altura: 150 as number,
      validation: false as boolean,
      referenciaBancaria: "" as string,
      cajero: "" as string | string[] | null,
      corte: "" as string | string[] | null,
      data: null as CorteCaja | null,
      icons: {
        ref: mdiFileDocument,
        up: mdiChevronUp,
        down: mdiChevronDown,
      } as Record<string, string>,
      ingreso: ModoPago.Ingreso as ModoPago,
      egreso: ModoPago.Egreso as ModoPago,
      billete: TipoDenominacion.Billete as TipoDenominacion,
      moneda: TipoDenominacion.Moneda as TipoDenominacion,
      headersOps: [
        { text: "", value: "data-table-expand" },
        {
          text: "Fecha",
          value: "created",
          align: "start",
          sortable: true,
        },
        {
          text: "Folio",
          value: "folioAPD",
          align: "start",
          sortable: true,
        },
        {
          text: "Servicio",
          value: "servicio.nombreReportes",
          align: "start",
          sortable: true,
        },
        {
          text: "Importe",
          value: "",
          align: "end",
          sortable: false,
        },
        {
          text: "Comisión",
          value: "",
          align: "end",
          sortable: false,
        },
        {
          text: "Ingresos",
          value: "",
          align: "end",
          sortable: false,
        },
        {
          text: "Egresos",
          value: "",
          align: "end",
          sortable: false,
        },
        {
          text: "CND",
          value: "",
          align: "end",
          sortable: true,
        },
        {
          text: "Saldo",
          value: "",
          align: "end",
          sortable: true,
        },
      ] as DataTableHeader[],
    };
  },
  computed: {
    rules(): Rules {
      return this.$store.state.validationRules;
    },
    dataCorte(): CorteCaja[] {
      if (this.data) {
        return [this.data];
      }

      return [];
    },
    operaciones(): Operacion[] {
      if (this.data && this.data.operaciones) {
        return this.data.operaciones;
      }
      return [];
    },
    titulo(): string {
      if (this.data) {
        return "Detalle Corte de Caja: " + SelectoresModule.NombreCajero(this.data.idKiosko);
      }

      return "";
    },
    // Computed por Operacion
    getServicio() {
      return (data: Operacion): string => {
        if (data.estatusOperacion == EstatusOperacion.NoDispensadoPagado) {
          return "DND";
        } else {
          return data.servicio ? data.servicio.nombreReportes : "";
        }
      };
    },
    getIngresos() {
      return (pagos: Pago[]): number => {
        return pagos
          .filter((p) => p.modoPago == ModoPago.Ingreso && p.estatusPago == EstatusPago.Ingresado)
          .reduce((sum, p) => sum + (p.denominacion ?? 0) * (p.cantidad ?? 0), 0);
      };
    },
    getEgresos() {
      return (pagos: Pago[]): number => {
        return pagos
          .filter((p) => p.modoPago == ModoPago.Egreso && p.estatusPago == EstatusPago.Dispensado)
          .reduce((sum, p) => sum + (p.denominacion ?? 0) * (p.cantidad ?? 0), 0);
      };
    },
    getCND() {
      return (pagos: Pago[]): number => {
        return pagos
          .filter((p) => p.modoPago == ModoPago.Egreso && p.estatusPago == EstatusPago.NoDispensado)
          .reduce((sum, p) => sum + (p.denominacion ?? 0) * (p.cantidad ?? 0), 0);
      };
    },
    getImporte() {
      return (operacion: Operacion): number => operacion.importeServicio + operacion.impuestoServicio;
    },
    getComision() {
      return (operacion: Operacion): number =>
        operacion.importeComision +
        operacion.importeComisionTarjeta +
        operacion.importeComisionOtros +
        operacion.impuestoComision +
        operacion.impuestoComisionTarjeta +
        operacion.impuestoComisionOtros;
    },
    getTotal() {
      return (item: Operacion): number => {
        if (item.pagos) {
          if (item.estatusOperacion == EstatusOperacion.Cancelada) {
            return this.getIngresos(item.pagos) - (this.getEgresos(item.pagos) + this.getCND(item.pagos));
          } else if (item.estatusOperacion == EstatusOperacion.NoDispensadoPagado) {
            return (
              this.getImporte(item) + this.getComision(item) - this.getEgresos(item.pagos) + this.getCND(item.pagos)
            );
          } else {
            return (
              this.getImporte(item) +
              this.getComision(item) -
              this.getIngresos(item.pagos) +
              this.getEgresos(item.pagos) +
              this.getCND(item.pagos)
            );
          }
        } else {
          return this.getImporte(item) + this.getComision(item);
        }
      };
    },
    getCantidadDenominacion() {
      return (data: Pago[], modo: ModoPago, tipo: TipoDenominacion, denominacion: number): number => {
        let estatus: EstatusPago = EstatusPago.Dispensado;

        if (modo == ModoPago.Ingreso) {
          estatus = EstatusPago.Ingresado;
        } else {
          estatus = EstatusPago.Dispensado;
        }

        const pago = data.find(
          (p) =>
            p.modoPago == modo &&
            p.tipoDenominacion == tipo &&
            p.estatusPago == estatus &&
            p.denominacion == denominacion,
        );

        if (pago) {
          return pago.cantidad ?? 0;
        }

        return 0;
      };
    },
    getTable() {
      return (pagos: Pago[]) => {
        return [
          {
            id: 1,
            bi1000: this.getCantidadDenominacion(pagos, this.ingreso, this.billete, 1000),
            bi500: this.getCantidadDenominacion(pagos, this.ingreso, this.billete, 500),
            bi200: this.getCantidadDenominacion(pagos, this.ingreso, this.billete, 200),
            bi100: this.getCantidadDenominacion(pagos, this.ingreso, this.billete, 100),
            bi50: this.getCantidadDenominacion(pagos, this.ingreso, this.billete, 50),
            bi20: this.getCantidadDenominacion(pagos, this.ingreso, this.billete, 20),
            mi50: this.getCantidadDenominacion(pagos, this.ingreso, this.moneda, 50),
            mi20: this.getCantidadDenominacion(pagos, this.ingreso, this.moneda, 20),
            mi10: this.getCantidadDenominacion(pagos, this.ingreso, this.moneda, 10),
            mi5: this.getCantidadDenominacion(pagos, this.ingreso, this.moneda, 5),
            mi2: this.getCantidadDenominacion(pagos, this.ingreso, this.moneda, 2),
            mi1: this.getCantidadDenominacion(pagos, this.ingreso, this.moneda, 1),
            be100: this.getCantidadDenominacion(pagos, this.egreso, this.billete, 100),
            be50: this.getCantidadDenominacion(pagos, this.egreso, this.billete, 50),
            be20: this.getCantidadDenominacion(pagos, this.egreso, this.billete, 20),
            me20: this.getCantidadDenominacion(pagos, this.egreso, this.moneda, 20),
            me10: this.getCantidadDenominacion(pagos, this.egreso, this.moneda, 10),
            me5: this.getCantidadDenominacion(pagos, this.egreso, this.moneda, 5),
            me2: this.getCantidadDenominacion(pagos, this.egreso, this.moneda, 2),
            me1: this.getCantidadDenominacion(pagos, this.egreso, this.moneda, 1),
          },
        ];
      };
    },
    // Computed por Corte
    getVentasCorte() {
      return (data: Operacion[]) => {
        return data
          .filter(
            (o) =>
              o.estatusOperacion == EstatusOperacion.Aplicada ||
              o.estatusOperacion == EstatusOperacion.AplicadaIncompleta,
          )
          .reduce((sum, op) => sum + op.importeServicio + op.impuestoServicio, 0);
      };
    },
    getComisionesCorte() {
      return (data: Operacion[]) => {
        return data
          .filter(
            (o) =>
              o.estatusOperacion == EstatusOperacion.Aplicada ||
              o.estatusOperacion == EstatusOperacion.AplicadaIncompleta ||
              o.estatusOperacion == EstatusOperacion.Cobrada ||
              o.estatusOperacion == EstatusOperacion.CobradaIncompleta ||
              o.estatusOperacion == EstatusOperacion.Revision ||
              o.estatusOperacion == EstatusOperacion.ValorTransferido,
          )
          .reduce(
            (sum, op) =>
              sum +
              op.importeComision +
              op.importeComisionTarjeta +
              op.importeComisionOtros +
              op.impuestoComision +
              op.impuestoComisionTarjeta +
              op.impuestoComisionOtros,
            0,
          );
      };
    },
    getVNACorte() {
      return (data: Operacion[]) => {
        return data
          .filter(
            (o) =>
              o.estatusOperacion == EstatusOperacion.Cobrada ||
              o.estatusOperacion == EstatusOperacion.CobradaIncompleta ||
              o.estatusOperacion == EstatusOperacion.Revision ||
              o.estatusOperacion == EstatusOperacion.ValorTransferido,
          )
          .reduce((sum, op) => sum + op.importeServicio + op.impuestoServicio, 0);
      };
    },
    getCNDCorte() {
      return (data: Operacion[]) => {
        return data.reduce((sum, op) => sum + (op.pagos ? this.getCND(op.pagos) : 0), 0);
      };
    },
    getDNDCorte() {
      return (data: Operacion[]) => {
        return data
          .filter((o) => o.estatusOperacion == EstatusOperacion.NoDispensadoPagado)
          .reduce((sum, op) => sum + (op.pagos ? this.getEgresos(op.pagos) : 0), 0);
      };
    },
    getIngresosCorte() {
      return (data: Operacion[]) => {
        return data.reduce((sum, op) => sum + (op.pagos ? this.getIngresos(op.pagos) : 0), 0);
      };
    },
    getEgresosCorte() {
      return (data: Operacion[]) => {
        return data.reduce((sum, op) => sum + (op.pagos ? this.getEgresos(op.pagos) : 0), 0);
      };
    },
  },
  watch: {
    corte() {
      if (this.referenciaBancaria || this.corte) {
        this.validation = true;
      } else {
        this.validation = false;
      }
    },
    referenciaBancaria() {
      if (this.referenciaBancaria || this.corte) {
        this.validation = true;
      } else {
        this.validation = false;
      }
    },
  },
  methods: {
    changeCajeros(data: string | string[] | null) {
      this.referenciaBancaria = "";
      this.cajero = data;
    },
    changeCortes(data: string | string[] | null) {
      this.referenciaBancaria = "";
      this.corte = data;
    },
    async LoadCorte() {
      const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSistemaPagos = new ApiSistemaPagos(client);

      try {
        SpinnerModule.Show();

        if (this.referenciaBancaria) {
          this.data = (await apiSistemaPagos.ConsultarOperacionesCorte(this.referenciaBancaria)) as CorteCaja;
        } else if (this.corte && typeof this.corte == "string") {
          this.data = (await apiSistemaPagos.ConsultarOperacionesCorte(this.corte)) as CorteCaja;
        }
      } catch (e) {
        const err = e as ApiErrorResponse;
        SnackModule.Show(err.apiError.mensajeUsuario);
        this.data = null;
      } finally {
        SpinnerModule.Hide();
      }
    },
    async Visualizar(dialog: boolean, show: (value: boolean) => void) {
      await this.LoadCorte();

      if (this.data) {
        show(!dialog);
      }
    },
    async Exportar() {
      const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSistemaPagos = new ApiSistemaPagos(client);

      try {
        SpinnerModule.Show();
        let data = "";

        if (this.referenciaBancaria) {
          data = (await apiSistemaPagos.ConsultarOperacionesCorteExcel(this.referenciaBancaria)) as string;
        } else if (this.corte && typeof this.corte == "string") {
          data = (await apiSistemaPagos.ConsultarOperacionesCorteExcel(this.corte)) as string;
        }

        const blob = this.b64toBlob(data, "application/vnd.ms-excel");
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CorteCajaDetallado.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        const err = e as ApiErrorResponse;
        SnackModule.Show(err.apiError.mensajeUsuario);
        this.data = null;
      } finally {
        SpinnerModule.Hide();
      }
    },
    b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    rezise() {
      setTimeout(() => {
        const e = this.$refs.infoBox as HTMLDivElement;
        const h = this.$refs.tableHeader as HTMLDivElement;
        if (e && h) {
          this.altura = e.clientHeight - h.clientHeight;
        } else {
          this.altura = 150;
        }
      }, 500);
    },
  },
});
